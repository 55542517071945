import { Component, Input, OnChanges, AfterViewInit } from "@angular/core";

@Component({
  standalone: true,
  selector: "anonymous-chat-component",
  templateUrl: "./anonymous-chat-component.component.html",
  styleUrls: ["./anonymous-chat-component.component.scss"],
})
export class AnonymousChatComponent implements OnChanges, AfterViewInit {
  @Input() openChat!: boolean;

  window!: Window;

  ngOnChanges() {
    (window as any).fcWidget.open();
    (window as any).fcWidget.show();
  }

  ngAfterViewInit() {
    this.initializeChat();
  }

  initializeChat() {
    if (typeof window !== "undefined" && typeof (window as any).fcWidget !== "undefined") {
      (window as any).fcWidget.init({
        token: "494ed57d-ae9e-45c6-8917-f010a35ec138",
        host: "https://wchat.freshchat.com",
        externalId: 0,
        config: {
          content: {
            placeholders: {
              csat_reply: "Plaats hier jouw opmerkingen",
            },
            actions: {
              csat_no: "Nee",
              csat_yes: "Ja",
              push_notify_yes: "Ja",
              push_notify_no: "Nee",
              csat_submit: "Verstuur",
            },
            headers: {
              chat: "Bijles chat",
              chat_help: "Stel hier al je vragen. We helpen je graag!",
              csat_question: "Hebben we je geholpen?",
              csat_no: "Nee",
              csat_yes: "Ja",
              push_notification: "Mis geen antwoorden! Sta push notificaties toe?",
              csat_yes_question: "Hoe vond je jouw ervaring met de bijles chat?",
              csat_no_question: "Wat hadden we beter kunnen doen?",
              csat_thankyou: "Bedankt voor je reactie!",
              csat_rate_here: "Submit your rating here",
            },
          },
        },
      });
    }
  }
}
